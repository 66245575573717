<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/attendance' }">出勤查询</el-breadcrumb-item>
          <el-breadcrumb-item>出勤详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column label="任务名称">
            <template>
              {{ row.taskName }}
            </template>
          </el-table-column>
          <el-table-column label="员工姓名" width="100">
            <template>
              {{ row.staffName }}
            </template>
          </el-table-column>
          <el-table-column prop="date" label="出勤日期" width="150">
            <template slot-scope="scope">
              {{ scope.row.date ? dayjs(scope.row.date).format('YYYY-MM-DD'):'-' }}
            </template>
          </el-table-column>
          <el-table-column prop="mileageCount" label="里程段数" width="100"></el-table-column>
          <el-table-column prop="startDate" label="段数时长" width="800">
            <template slot-scope="scope">
              <span v-for="(i,k) in scope.row.durationList" :key="k">
                {{ scope.row.durationList.length-1 > k ? '第'+(scope.row.durationList.length-k)+'段（'+ dateSecond(i) +'）、' : '第'+(scope.row.durationList.length-k)+'段（'+dateSecond(i)+'）' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><div @click="details(scope.row)">查看地图</div></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {},
      row: {},
      tableData: [],
      sheshi: [],
      staffId: 0,
      roleId: 0
    }
  },
  methods: {
    dayjs,
    onOff(row) {
      this.$ajax.post("ruleStatus", {
        id: row.id,
        status: row.status == 0 ? 1 : 0
      }).then((res) => {
        this.loadTableData();
      });
    },
    onSubmit() {
      this.loadTableData()
    },
    details(row) {
      this.$router.push({ name: 'attendanceDetailTrack', params: {row: row}})
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadTableData()
    },
    dateSecond(val) {
      if (val) {
        let s = val % 60
        let _s = val - s
        let m = (_s / 60) % 60
        let h = (_s / 60 - m) / 60
        return `${h>9 ? h:'0'+h}:${m>9 ? m:'0'+m}:${s>9 ? s:'0'+s}`
      } else {
        return '00时00分00秒'
      }
    },
    loadTableData() {
      this.loading = true
      this.$ajax.post('queryAttendanceDetail', {
        endDate: this.row.endDate,
        pageNum: this.page,
        pageSize: this.size,
        routineTaskCode: this.row.routineTaskCode,
        staffId: this.row.staffId,
        startDate: this.row.startDate
      }).then(res => {
        this.loading = false
        this.tableData = res.data
        this.total = res.total
      }).catch(err=>{
        this.loading = false
      })
    },
    getLastPage() {
      if(sessionStorage.getItem('detail')) {
        this.page = Number(sessionStorage.getItem('currentPage'))
      }else {
        this.page = 1
        sessionStorage.removeItem('currentPage')
      }
    },
    load_sheshi() {
      this.$ajax.get("facilityQueryAll").then((res) => {
        this.sheshi = res.data;
      });
    },
  },
  mounted() {
    if(this.$route.params.row) {
      this.row = this.$route.params.row
      sessionStorage.setItem('attendanceRow',JSON.stringify(this.$route.params.row))
		}

    if(sessionStorage.getItem('attendanceRow')){
      this.row = JSON.parse(sessionStorage.getItem('attendanceRow'))
    }
    
    this.load_sheshi();
    this.getLastPage()
    this.loadTableData()
    this.staffId = sessionStorage.getItem('id')
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    sessionStorage.removeItem('detail')
  }
}
</script>

<style lang="less" scoped>
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  margin-bottom: 20px;
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.btn {
  float: right;
}
.search {
  padding: 0 10px;
}
</style>